<template>
  <div>
    <searchHeader :keyword="keyword" :callback="function(string){ setSearch(string) }"/>
    <transition name="flixFadeIn">
      <div class="flix-container" :key="key">
        <flixLoader v-if="loader" />
        <div v-if="!loader && results.bookings.length">
          <h1 class="flix-html-h2 flix-text-center">{{ results.bookings.length }} {{ $tc("message.result", results.bookings.length) }} <flixIcon :id="'chevron-down'" /></h1>
          <searchResults :keyword="keyword" :results="results.bookings" />
        </div>
        <div v-if="keyword && !loader && !results.bookings.length">
          <h3 class="flix-html-h2 flix-text-center"><flixIcon :id="'ban-circle'" /> {{ $tc("message.noEntry", 2) }}</h3>
        </div>
      </div>
  </transition>
  </div>
</template>
<script>
export default {
  components: {
    searchHeader () { return import('@/components/search/header') },
    searchResults () { return import('@/components/search/results') }
  },
  props: {},
  data () {
    return {
      keyword: this.$store.getters.search.keyword,
      loader: false,
      results: this.getCheckResults(this.$store.getters.search.results),
      key: new Date().getTime()
    }
  },
  methods: {
    getCheckResults (results) {
      var bookings = this.$store.getters.bookings

      if (!results.bookings.length) {
        return results
      }
      var error = false
      results.bookings.forEach(function (r, k) {
        if (typeof bookings[r.link.params.form] !== 'object' || JSON.stringify(bookings[r.link.params.form]).indexOf(r.entry.ID) === -1) {
          results.bookings.splice(k, 1)
          error = true
        }
      })

      if (error) {
        this.$store.commit('setSearch', {
          keyword: this.keyword,
          results: results
        })
      }

      return results
    },
    setSearch (string) {
      this.loader = true
      this.keyword = string.trim()
      this.results.bookings = this.getBookingResults()
      this.$store.commit('setSearch', {
        keyword: this.keyword,
        results: this.results
      })
      this.key = new Date().getTime()
    },
    getBookingResults () {
      var bookings = this.$store.getters.bookings
      var assistents = this.$store.getters.assistentsByID
      var colors = this.$store.getters.colors
      var k = 0
      Object.keys(assistents).forEach(function (a) {
        if (typeof assistents[a].color !== 'string') {
          assistents[a].color = colors[k]
        }
        k++
      })
      var r = []
      Object.keys(bookings).forEach(function (k) {
        var form = k
        Object.values(bookings[k]).forEach(function (entry) {
          var error = true
          if (entry.booking_id === this.keyword) {
            error = false
          }
          if (JSON.stringify(Object.values(entry.data)).toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1) {
            error = false
          }
          if (entry.email.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1) {
            error = false
          }
          if (assistents[entry.form].title.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1) {
            error = false
          }
          if (error === false) {
            var color = ''
            if (typeof assistents[entry.form].color === 'string') {
              color = 'color: ' + assistents[entry.form].color
            }
            r.push({
              title: '<b style="' + color + '">' + assistents[entry.form].title + '</b><br /><span style="' + color + '">' + entry.booking_date + '</span><hr class="flix-html-hr" style="margin: 8px 0"/>' + entry.email + '<br />#' + entry.booking_id,
              link: { name: 'dashboardBookingDetails', params: { user: this.$store.getters.user.md5_id, form: form, entryID: entry.md5ID } },
              entry: { form: entry.form, ID: entry.booking_id }
            })
          }
        }.bind(this))
      }.bind(this))
      this.loader = false
      return r
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
